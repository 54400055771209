<script setup>
const router = useRouter()
const route = useRoute()
const cart = useCartStore()
const auth = useAuthStore()
const config = useRuntimeConfig()

const enabled = ref(false)
const recognizing = ref(false)
const final_transcript = ref('')
const recognition = ref(null)
const searching = ref(true)

const enter = () => {
  if (final_transcript.value !== '') {
    router.push({ name: 'pretraga', query: { text: final_transcript.value } })
  }
}

onMounted(() => {
  final_transcript.value = ''
  enabled.value = false
  if ('webkitSpeechRecognition' in window) {
    enabled.value = true
    recognition.value = new webkitSpeechRecognition()
    // The default value for continuous is false, meaning that when the user stops talking, speech recognition will end.
    recognition.value.continuous = false
    recognition.value.interimResults = true
    recognition.value.lang = 'sr-RS'

    recognition.value.onstart = () => {
      recognizing.value = true
    }
    recognition.value.onresult = (event) => {
      for (let i = event.resultIndex; i < event.results.length; ++i) {
        if (event.results[i].isFinal) {
          final_transcript.value += event.results[i][0].transcript
        }
        else {
          // interim_transcript.value += event.results[i][0].transcript
        }
      }
    }
    // recognition.value.onerror = e => {}
    recognition.value.onend = () => {
      enter()
      recognizing.value = false
    }
  }
})

if (route.name === 'pretraga' && route.query?.text) {
  final_transcript.value = route.query?.text
}

watch(() => route.query, (x) => {
  if (x?.text) {
    final_transcript.value = x?.text
  }
})

const startSpeak = () => {
  if (recognizing.value) {
    recognition.value.stop()
    return
  }
  final_transcript.value = ''
  recognition.value.lang = 'sr-RS'
  recognition.value.start()
}
const clear = () => {
  router.replace({ name: 'pretraga', query: null })
  final_transcript.value = ''
  // emits('search', '')
}

onUnmounted(() => {
  enabled.value = false
  if (recognition.value) {
    recognition.value.stop()
  }
  if (route.name === 'pretraga') {
    const query = Object.assign({}, route.query)
    delete query.text
    router.replace({ query })
  }
})

const logout = async () => {
  const ct = useCookie('ct')
  const recently = useCookie('recently')
  const woocommerce_cart_hash = useCookie('woocommerce_cart_hash')
  const woocommerce_items_in_cart = useCookie('woocommerce_items_in_cart')
  ct.value = null
  recently.value = null
  woocommerce_cart_hash.value = null
  woocommerce_items_in_cart.value = null
  auth.removeMe()
  await fetch(config.public.CMS + '/wp-json/stilles/v1/logout')
  cart.updateCart(null)
  await cart.init()
  await router.push('/')
}
const to = computed(() => {
  if (route.name !== 'index' && route.name !== 'login') {
    return { name: 'login', query: { redirect: route.path } }
  }
  else {
    return { name: 'login' }
  }
})
</script>

<template>
  <div class="prenav">
    <div class="a">
      <!-- 021 6 800 900 -->
      <!-- 021 6 800 909 -->
      <!-- <button @click="logout">LOGOUT&nbsp;&nbsp;&nbsp;&nbsp;</button> -->

      <div class="b">
        <a href="tel:+381216800900">
          <vIcon id="call" />
          <span class="dn">021 680 09 00</span>
        </a>
        <a
          href="tel:+381216800909"
          class="dn"
        >
          021 680 09 09</a>
      </div>

      <div :class="['df', { fx: route.name === 'pretraga' }]">
        <NuxtLink
          v-if="route.name !== 'pretraga'"
          to="/pretraga"
          class="search"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 56 56"
            fill="currentColor"
          >
            <path
              d="M26.688 12.66c.28 0 .421-.164.492-.422.726-3.914.68-4.008 4.758-4.781.28-.047.445-.21.445-.492 0-.281-.164-.446-.446-.492-4.054-.82-3.937-.914-4.757-4.782-.07-.257-.211-.421-.492-.421-.282 0-.422.164-.493.421-.82 3.868-.68 3.961-4.758 4.782-.257.046-.445.21-.445.492 0 .281.188.445.445.492 4.079.82 4.032.867 4.758 4.781.07.258.211.422.492.422ZM15.344 28.785c.445 0 .75-.281.797-.703.843-6.258 1.054-6.258 7.523-7.5.422-.07.727-.352.727-.797 0-.422-.305-.726-.727-.797-6.469-.89-6.703-1.101-7.523-7.476-.047-.422-.352-.727-.797-.727-.422 0-.727.305-.774.75-.773 6.281-1.101 6.258-7.523 7.453-.422.094-.727.375-.727.797 0 .469.305.727.82.797 6.376 1.031 6.657 1.195 7.43 7.453.047.469.352.75.774.75Zm15.89 25.945c.61 0 1.055-.445 1.172-1.078 1.664-12.843 3.469-14.789 16.172-16.195.656-.07 1.102-.563 1.102-1.172 0-.61-.446-1.078-1.102-1.172-12.703-1.406-14.508-3.351-16.172-16.195-.117-.633-.562-1.055-1.172-1.055-.609 0-1.054.422-1.148 1.055-1.664 12.844-3.492 14.789-16.172 16.195-.68.094-1.125.563-1.125 1.172 0 .61.445 1.102 1.125 1.172 12.656 1.664 14.414 3.375 16.172 16.195.094.633.539 1.078 1.148 1.078Z"
            />
          </svg>
          <span>Pretraga</span>
        </NuxtLink>
        <span
          v-if="route.name !== 'pretraga'"
          class="separator"
        >|</span>
        <div
          v-if="!auth.me"
          class="c"
        >
          <div class="cnt">
            <RouterLink
              :to="{ name: 'registracija' }"
              class="cnt fr dn"
            >
              <strong>NAPRAVI NALOG </strong>
            </RouterLink>
            <span class="separator dn">|</span>
            <RouterLink
              :to="to"
              class="cnt fl"
            >
              PRIJAVI SE
            </RouterLink>
          </div>
        </div>
        <div
          v-else
          class="cnt"
        >
          <NuxtLink
            :to="{ name: 'profil' }"
            class="profile"
          >
            <span v-text="auth.me.user_nicename || auth.me.user_display_name || auth.me.user_email" />
          </NuxtLink>
          <span
            class="cnt"
            @click="logout"
          >
            ODJAVI SE
          </span>
        </div>
        <span class="separator">|</span>
        <div class="icon">
          <VToggleTheme />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.back {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 400;
  font-size: 12px;
  cursor: pointer;
}

.pulse {
  position: absolute;
  top: 0;
  right: 0;
  display: inline;
  background: rgb(255 0 0 / 5%);
  border-radius: 50%;
  height: 20px;
  width: 20px;
  box-shadow: 0 0 0 0 rgba(255 0 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255 0 0 / 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255 0 0 / 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255 0 0 / 0);
  }
}

.df {
  display: flex;
  align-items: center;
  /* outline: 1px dashed; */
  line-height: 0;

  &.fx {
    min-width: 70px;
    justify-content: flex-end;
  }
}

.icon {
  position: relative;
  width: 24px;
  height: 24px;
}

:deep(svg) {
  color: rgb(var(--color-rgb) / .5);
}

.search {
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  position: relative;
  text-transform: uppercase;
  font-size: 12px;

  & span {
    color: rgb(var(--color-rgb) / .5);
  }
}

.admin {
  background-color: var(--brand-color);
  color: white;
  font-size: 12px;
  padding: 0 4px;
  border-radius: 3px;
  margin: 0 4px 0 10px;
}

.profile {
  text-transform: uppercase;

  & img {
    width: 16px;
    height: 16px;
  }
}

.prenav {
  width: 100%;
  height: 48px;

  & .a {
    padding: 0 24px;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    font-size: 13px;
    margin: 0 auto;
    font-weight: 600;
    max-width: 1488px;
  }
}

a {
  font-size: 12px;
  display: flex;
  align-items: center;
  color: rgb(var(--color-rgb) / .5);
  margin-right: 16px;

  & svg {
    margin-right: 8px;
  }

  &+a {
    margin-right: 0;
  }

  &.router-link-active {
    color: var(--brand-color);
  }
}

.b {
  display: flex;
  color: rgb(var(--color-rgb) / .5);
}

.c {
  font-size: 13px;
  color: rgb(var(--color-rgb) / .5);
  display: flex;
  align-items: center;

  & svg {
    margin-left: 8px;
  }
}

.cnt {
  display: flex;
  align-items: center;

  & a {
    margin-right: 8px;
  }
}

.separator {
  padding-right: 8px;
  color: rgb(var(--color-rgb) / .25);
}

@media (max-width: 512px) {
  .dn {
    display: none;
  }

  .search {
    margin-right: 8px;
  }

  .prenav .a {
    padding: 0 24px;
  }
}
</style>
